// core version + navigation, pagination modules:
import Swiper, { Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Autoplay]);

const mySwiper = () => new Swiper('.swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
        980: {
            slidesPerView: 3,
            spaceBetween: 15,
        }
    }
});
mySwiper();